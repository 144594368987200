.header-top-01 {
	border-bottom: 1px solid var(--#{$prefix}gray-100);
	.nav-link {
        color: var(--#{$prefix}gray-800);
        &:hover {
            color: var(--#{$prefix}gray-900);
        }
    }
    .navbar-transparent.navbar-light:not(.headroom--not-top) & {
    	color: rgba(var(--#{$prefix}white-rgb), 0.8);
    	border-bottom: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);
        .nav-link {
            color: rgba(var(--#{$prefix}white-rgb), 0.8);
            &:hover {
                color: var(--#{$prefix}white);
            }
        }
    }
}

// Header Button
.header-btn {
    border: 1px solid var(--#{$prefix}primary);
    color: var(--#{$prefix}primary);
    background: transparent;
    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }
    .navbar-transparent.navbar-light:not(.headroom--not-top) & {
        border: 1px solid var(--#{$prefix}white);
        color: var(--#{$prefix}white);
        background: transparent;
        &:hover {
            background: var(--#{$prefix}white);
            color: var(--#{$prefix}dark);
        }
    }
}


.header-top-light {
    .nav-link {
        color: rgba(var(--#{$prefix}white-rgb), 0.8);
        &:hover {
            color: var(--#{$prefix}white);
        }
    }
}


.header-option-01 {
	
	// Header Top
	.header-top {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	// Search 
	.header-search {
		max-width: 300px;
		.header-search-form {
		    display: flex;
			position: relative;
		}
		.form-control {
		    border-top: none;
		    border-left: none;
		    border-right: none;
		    border-radius: 0;
		    padding-left: 42px;
		}
		.search-btn {
			border: none;
		    padding: 0;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    display: inline-flex;
		    align-items: center;
		    justify-content: center;
		    width: 35px;
		}
	}

	// Logo 
	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-height: 60px;
		}
	}

	// Header Icons
	.header-icons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.h-col {
			margin-left: 0.8rem;
			position: relative;
		}
		.h-icon {
			width: 40px;
			height: 40px;
			background-color: transparent;
			border: none;
			color: var(--#{$prefix}dark-text);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2rem;
			padding: 0;
			position: relative;
			border: 1px solid var(--#{$prefix}gray-300);
			border-radius: $border-radius;
			sup {
			    position: absolute;
			    top: -1px;
			    right: 1px;
			    background: var(--#{$prefix}primary);
			    color: var(--#{$prefix}white);
			    height: 15px;
			    margin: 0;
			    display: inline-flex;
			    align-items: center;
			    justify-content: center;
			    line-height: 1;
			    padding: 0 5px;
			    border-radius: 30px;
			    font-size: 10px;
			}
		}
		.dropdown-toggle {
			&:after {
				display: none;
			}
		}
		.h-burgur-icon {
			width: 40px;
	        height: 40px;
	        border-radius: $border-radius;
	        padding: 0;
	        color: var(--#{$prefix}dark-text);
	        border: 1px solid var(--#{$prefix}gray-300);
			span {
		        width: 50%;
		        height: 2px;
		        background: currentColor;
		        display: inline-block;
		        vertical-align: middle;
		        box-shadow: 0 -6px 0 0 currentColor, 0 6px 0 0 currentColor;
		    }
		}
	}

	.navbar {
		--#{$prefix}navbar-padding-y: 0;
	}

	@include media-breakpoint-up(lg) {
		.navbar {
			border-bottom: 1px solid var(--#{$prefix}gray-300);
			.navbar-nav {
				--bs-nav-link-padding-y: 1rem;
			}
			.nav-link {
				text-transform: uppercase;
				font-weight: 500;
				--#{$prefix}nav-link-font-size: 0.90em;
				color: var(--#{$prefix}dark-text);
			}
		}
	}

}