.theme-switch-01 {
    width: 40px;
    border-radius: 50%;
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 23px;
    //color: var();
    .main-header.navbar-transparent:not(.headroom--not-top) & {
        color: var(--#{$prefix}white);
    }
    [data-bs-theme="dark"] & {
        color: var(--#{$prefix}white);
    }
    &:after {
        display: none;
    }
}