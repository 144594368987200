@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400;700&display=swap');

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:                 #fff !default;
$gray-25:               #FCFCFD !default;
$gray-50:               #F9FAFB !default;
$gray-100:              #F2F4F7 !default;
$gray-200:              #EAECF0 !default;
$gray-300:              #D0D5DD !default;
$gray-400:              #98A2B3 !default;
$gray-500:              #667085 !default;
$gray-600:              #475467 !default;
$gray-700:              #344054 !default;
$gray-800:              #1D2939 !default;
$gray-900:              #101828 !default;
$black:                 #000 !default;
// scss-docs-end gray-color-variables


// fusv-disable
$grays: (
  "25": $gray-25,
  "50": $gray-50,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// fusv-enable


$blue:        #2F80ED;
$indigo:      #6e00ff;
$purple:      #510FA8;
$pink:        #f074ad;
$red:         #f25767;
$orange:      #FFBE3D;
$yellow:      #ffc431;
$green:       #5cc9a7;
$teal:        #50b5ff;
$cyan:        #4bd6e5;



// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map



// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
) !default;

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
) !default;

$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
) !default;

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
) !default;

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
) !default;

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
) !default;

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
) !default;

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
) !default;

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
) !default;

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
) !default;
// fusv-enable

// scss-docs-start theme-color-variables

// scss-docs-start theme-color-variables

$primary:       #009fa3 !default;
$secondary:     #FDE7DA !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          #121F38 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "white":      $white,
  "black":      $black,
  "dark":       $dark
) !default;
// scss-docs-end theme-colors-map


// fusv-disable
$primary-100: tint-color($primary, 80%) !default;
$primary-200: tint-color($primary, 60%) !default;
$primary-300: tint-color($primary, 40%) !default;
$primary-400: tint-color($primary, 20%) !default;
$primary-500: $primary !default;
$primary-600: shade-color($primary, 20%) !default;
$primary-700: shade-color($primary, 40%) !default;
$primary-800: shade-color($primary, 60%) !default;
$primary-900: shade-color($primary, 80%) !default;


$primary-text:            $primary-600 !default;
$primary-bg-subtle:       $primary-100 !default;
$primary-border-subtle:   $primary-200 !default;


// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   1.65 !default;
//$min-contrast-ratio:   4.5 !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.


$enable-rounded:              false !default;
$enable-negative-margins:     true !default;

// Prefix for :root CSS variables

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 3.5,
  9: $spacer * 4,
  10: $spacer * 5,
  11: $spacer * 6,
  12: $spacer * 9,
) !default;


// Body
//
// Settings for the `<body>` element.
$body-color:            #555 !default;


// Links
//
// Style anchor elements.

$link-decoration:                         none !default;
$line-height-base:     1.7 !default;
$font-weight-base:     400 !default;




// Fonts
//
// Font, line-height, and color for body text, headings, and more.
//$path-to-fonts: "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" !default;
$font-family-sans-serif:      'Open Sans', -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family:        'Old Standard TT', serif !default;
$font-family-sans-serif:                    $font-family-sans-serif-cutom !default;

$font-size-base:                            1rem !default;
$font-size-xl:                              $font-size-base * 1.25 !default;
$font-size-lg:                              $font-size-base * 1.125 !default;
$font-size-sm:                              $font-size-base * .88 !default;
$font-size-xs:                              $font-size-base * .80 !default;

$font-weight-normal:                        400 !default;
$font-weight-medium:                        500 !default;
$font-weight-semibold:                      600 !default;
$font-weight-bold:                          700 !default;

$line-height-base:                          1.6 !default;
$line-height-sm:                            1.4 !default;
$line-height-lg:                            1.8 !default;

$font-weight-base:                          500 !default;

$h1-font-size:                              $font-size-base * 2.5 !default;
$h2-font-size:                              $font-size-base * 2 !default;
$h3-font-size:                              $font-size-base * 1.75 !default;
$h4-font-size:                              $font-size-base * 1.5 !default;
$h5-font-size:                              $font-size-base * 1.25 !default;
$h6-font-size:                              $font-size-base !default;
$headings-color:                            #121F38 !default;
$headings-font-weight:                      700 !default;

// Modifying default Bootstrap's $font-sizes map
$font-sizes: (
  1:                                        $h1-font-size,
  2:                                        $h2-font-size,
  3:                                        $h3-font-size,
  4:                                        $h4-font-size,
  5:                                        $h5-font-size,
  6:                                        $h6-font-size,
  'xl':                                     $font-size-xl,
  'lg':                                     $font-size-lg,
  'base':                                   $font-size-base,
  'sm':                                     $font-size-sm,
  'xs':                                     $font-size-xs
) !default;

$lead-font-size:                            $font-size-base * 1.5 !default;
$lead-font-weight:                          $font-weight-normal !default;

// Modifying default Bootstrap's $display-font-sizes map
$display-font-sizes: (
  1: $font-size-base * 5,
  2: $font-size-base * 4.5,
  3: $font-size-base * 4,
  4: $font-size-base * 3.5,
  5: $font-size-base * 3,
  6: $font-size-base * 2.625
) !default;

$display-font-weight:                       $font-weight-bold !default;



// scss-docs-start box-shadow-variables
$box-shadow-xs:               0px 1px 2px rgba(var(--#{$prefix}dark-rgb), .08) !default;
$box-shadow-sm:               0px 1px 3px rgba(var(--#{$prefix}dark-rgb), 0.1), 0px 1px 2px rgba(var(--#{$prefix}dark-rgb), 0.06) !default;
$box-shadow:                  0px 4px 8px -2px rgba(var(--#{$prefix}dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--#{$prefix}dark-rgb), 0.06) !default;
$box-shadow-lg:               0px 12px 16px -4px rgba(var(--#{$prefix}dark-rgb), 0.08), 0px 4px 6px -2px rgba(var(--#{$prefix}dark-rgb), 0.03) !default;
$box-shadow-xl:               0px 20px 24px -4px rgba(var(--#{$prefix}dark-rgb), 0.08), 0px 8px 8px -4px rgba(var(--#{$prefix}dark-rgb), 0.03) !default;
$box-shadow-2xl:              0px 24px 48px -12px rgba(var(--#{$prefix}dark-rgb), 0.18) !default;
$box-shadow-3xl:              0px 32px 64px -12px rgba(var(--#{$prefix}dark-rgb), 0.14) !default;


$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;




$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-sm:            .2rem !default;
$border-radius-lg:            .75rem !default;
$border-radius-pill:          50rem !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:         .575rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-font-size:         0.9rem !default;

$input-btn-padding-y-sm:      .425rem !default;
$input-btn-padding-x-sm:      1rem !default;
$input-btn-focus-box-shadow:    unset !default;
//$input-btn-font-size-sm:      $font-size-sm !default;
//$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .825rem !default;
$input-btn-padding-x-lg:      1.75rem !default;
$input-btn-font-size-lg:      $font-size-base !default;
$input-btn-line-height-lg:    $line-height-base !default;

$input-btn-focus-width:       .125rem !default;

$input-btn-disabled-color:    $gray-600 !default;

// Forms

$input-border-color:                    var(--#{$prefix}gray-300) !default;

// scss-docs-start form-label-variables
$form-label-margin-bottom:              .5rem !default;
$form-label-font-size:                  .85rem !default;
$form-label-color:                      var(--#{$prefix}gray-900) !default;

$form-switch-width:               3em !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               1.5rem !default;

$btn-font-weight:             500 !default;
$btn-white-space:             nowrap !default;
$btn-focus-width:             0 !default;

$btn-box-shadow:              unset !default;
$btn-focus-box-shadow:        unset !default;
$btn-active-box-shadow:       unset !default;


$btn-font-size-lg:            $font-size-base !default;
$btn-font-size-xs:            $font-size-xs !default;

//$btn-padding-y-xs:            .375rem !default;
$btn-padding-x-xs:            .875rem !default;

$btn-link-color:              $gray-700 !default;
$btn-link-hover-color:        $primary !default;
$btn-link-decoration:         none !default;
$btn-link-hover-decoration:   none !default;

$btn-link-light-color:        rgba($white, .7) !default;
$btn-link-light-hover-color:  $white !default;


$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  1rem !default;
$navbar-nav-link-padding-x:         1rem !default;


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-padding-x:                0.7rem !default;
$dropdown-padding-y:                0.7rem !default;
$dropdown-font-size:                $font-size-base * .95 !default;
$dropdown-link-hover-color:         $primary !default;
$dropdown-link-hover-bg:            rgba(var(--#{$prefix}primary-rgb), .10) !default;
$dropdown-border-color:             var(--#{$prefix}gray-300) !default;


// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer !default;
$card-spacer-x:                     $spacer !default;
$card-title-spacer-y:               $spacer * .5 !default;
$card-border-width:                 $border-width !default;
$card-border-color:                 var(--#{$prefix}gray-200) !default;
$card-border-radius:                $border-radius + 0.15rem !default;
$card-box-shadow:                   null !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                $card-spacer-y * .5 !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       var(--#{$prefix}body-bg) !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           var(--#{$prefix}body-bg) !default;
$card-img-overlay-padding:          $spacer !default;
//$card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables



// scss-docs-start backdrop-filter-variables
$bg-blur-sm:  blur(4px) !default;
$bg-blur-md:  blur(8px) !default;
$bg-blur-lg:  blur(12px) !default;
$bg-blur-xl:  blur(20px) !default;





// Dropdown Size
$px-dropdown-menu-md:         22rem !default;
$px-dropdown-menu-lg:         35rem !default;
$px-dropdown-menu-xl:         42rem !default;


// Section
$px-section-xl-padding-y:   5.62500rem !default;
$px-section-lg-padding-y:   3.75rem !default;
$px-section-md-padding-y:   3.12500rem !default;
$px-section-padding-y:      2.5rem !default;

// Avatar & Icon Size
$avatar-size-base: 3.125rem;
$avatar-size-xxs: 1rem;
$avatar-size-xs: 1.75rem;
$avatar-size-sm: 2.6875rem;
$avatar-size-lg: 4.25rem;
$avatar-size-xl: 5.625rem;
$avatar-size-xxl: 7rem;
$avatar-size-xxxl: 8rem;


$avatar-font-size-base: 1rem;
$avatar-font-size-xxs: .60rem;
$avatar-font-size-xs: .70rem;
$avatar-font-size-sm: .8125rem;
$avatar-font-size-lg: 1.25rem;
$avatar-font-size-xl: 2rem;
$avatar-font-size-xxl: 3rem;
$avatar-font-size-xxxl: 3.5rem;
